







































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
	
  }
})
export default class OrderBook extends Vue {
  

  @Prop({})
  order_book!: any;
  
 
  width: number = 300;
  
  height: number = 150;
  
  get bid_map() {
	 
	if(this.order_book.length == 0) return [];
	
	let items = this.order_book.bids_all.slice(0);
	let asks = this.order_book.asks_all.slice(0);
	let points = [];
	
	let top_bid = items[0].price;
	let reference = top_bid * 0.95;
	

	
	items.push({
		price: reference,
		amount: items[items.length - 1].amount
	})
	
	let max_amount = Math.max ( items[items.length - 1].amount, asks[asks.length - 1].amount );
	let max_price = items[items.length - 1].price;

	console.time("Bid")
	for(let item of items) {
		let y = this.height - (this.height * ((top_bid - item.price) / (top_bid - max_price)));
		let x = this.width - ((parseFloat(item.amount) / max_amount) * this.width);
		
		points.push([x, y].join(","))
				
	}
	console.timeEnd("Bid")
	
	points.push([this.width,0].join(','))
	points.push([this.width,this.height].join(','))
	points.push(points[0])
	
	return points.join(" ");
	
  }
  
  get ask_map() {
	  
	  if(this.order_book.length == 0) return [];
	  
	  let items = this.order_book.asks_all.slice(0);
	  let bids = this.order_book.bids_all.slice(0);
	  
	  let points = [];
	  
	  let top_ask = items[0].price;
	  let reference = top_ask * 1.05;
	
	
	  items.push({
		  price: reference,
		  amount: items[items.length - 1].amount
	  })
	  
	  let max_amount = Math.max ( items[items.length - 1].amount , bids[bids.length - 1].amount );
	  let max_price = items[items.length - 1].price;
	  
	  console.time("Ask")
	  for(let item of items) {
		  let y = 3 + this.height + (this.height * ((item.price - top_ask) / (max_price - top_ask )));
		  let x = this.width - ((parseFloat(item.amount) / max_amount) * this.width);
		  
		  points.push([x, y].join(","))
		  
	  }
	  console.timeEnd("Ask")
	  
	  points.push([this.width, (this.height * 2) + 3].join(','))
	  points.push([this.width,this.height + 3].join(','))
	  points.push(points[0])
	  
	
	  return points.join(" ");
	  
  }

}
