




















import Header from '@/components/Header.vue';

import { Component, Vue, Watch } from "vue-property-decorator";
import router from "@/router"

import axios from 'axios';
// Components
// import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
// import Footer from "@/components/Footer.vue"; // @ is an alias to /src
import AUShare from "@/components/aus/au_share.vue";
import USShare from "@/components/us/us_share.vue";
import AUETF from "@/components/aus/au_etf.vue";
import Crypto from "@/components/crypto/crypto.vue";
import Order from "@/components/Order.vue";

@Component({
  components: {
    Header,
    AUShare,
    USShare,
    Crypto,
    Order
  }
})
export default class Security extends Vue {
  
  public symbol: string = '';
  public security_type: string = '';
  
  created() {
    
    let { security_type } = this.$route.meta;
    let { symbol } = this.$route.params;
    
    this.symbol = symbol;
    this.security_type = security_type;
    
    console.log(security_type);
  }
  
  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newVal: any) {

      let { security_type } = this.$route.meta;
      let { symbol } = this.$route.params;
      
      
      this.symbol = symbol;
      this.security_type = security_type;
      
      console.log(security_type);
      
  }
  
  mounted() {
    // console.log(router);
  
    
  }
  

}


