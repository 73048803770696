


































































import { Component, Vue, Prop } from "vue-property-decorator";

import router from "@/router";

import axios from 'axios';

// Components
// import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
// import Footer from "@/components/Footer.vue"; // @ is an alias to /src
@Component({
  components: {
  }
})
export default class Order extends Vue {
  
  @Prop() 
  symbol: any;

  @Prop() 
  security_type: any;
  public bid: number = 0;
  public ask: number = 0;
  public last: number = 0;
  // public ws_stream: any;
  public quantity: string = '';
  public amount_to_trade = '';
  public side: string = 'buy';
  public event_callbacks: any = [];



  created() {
			// Listen for messages
		this.$socket.addEventListener('message', this.wsOnMessage);
	
	}

  destroyed() {	
	// this.getSymbol(this.symbol);
		// this.ws_stream = new WebSocket('wss://ws.b8s.biz');
		
		// Listen for messages
		this.$socket.removeEventListener('message', this.wsOnMessage);
		
	}


  update_quantity() {
    
    let amount: any = this.amount_to_trade;

    if(!isNaN(parseFloat(amount))) {
      this.quantity = (parseFloat(amount) / this.bid).toFixed(8); 
    }
    else {
      this.quantity = '';
    }

  }
	
  wsOnMessage(ev: any) {
		let message = JSON.parse(ev.data);
		
		let event_type = message.type;
		

		if(event_type == "price_update") {
      
      let price_update = 	message.data[this.symbol];
      
      this.bid = price_update.best_bid;
      this.ask = price_update.best_ask;
      this.last = price_update.price;
      this.update_quantity();
    
    } else if(event_type == "order_event") {
      for(ev of this.event_callbacks) {
        // setTimeout((data: any) => {
          ev(message);
        // }, 2000, ev.data);
        
      }
    }
    
  }

  mounted() {
	  
  }

  async confirm(ev: any) {
    ev.preventDefault();

    let notional = this.amount_to_trade;
    let symbol = this.symbol;
    let side = this.side;

    await this.trade({
      notional, symbol, side
    })
  }

  async trade(options: any) {

    let {
      symbol, notional, side, kind, account_id
    } = options;

    let callback = (order: any) => {
      alert(`Order completed\n\nPrice: ${order.data.price}\nQuantity: ${order.data.quantity}`);
      this.event_callbacks = [];
    }

    this.event_callbacks.push(callback);

    let { data } = await axios({
      "method": "POST",
      "url": "https://b8s.biz/orders",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer 1234"
      },
      "data": {
        "notional": notional,
        "symbol": symbol,
        "kind": "market",
        "account_id": "1000",
        "side": side
      }
    });

    console.log(data);

    





  }
	


}



