










































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import router from "@/router";
import Chart from "@/components/Chart.vue";
import OrderBook from "@/components/OrderBook.vue";

import st from "@/store/securities.vue";

import axios from 'axios';
// Components

// import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
// import Footer from "@/components/Footer.vue"; // @ is an alias to /src
@Component({
	components: {
		Chart,
		OrderBook
	}
})
export default class ShareView extends Vue {

	@Prop() 
	symbol: any;

	public crypto_token: any = null;
	public selected_tab: string = 'details';
	public selected_timeframe: string = '126';
	public ws_stream: any;
	
	mounted() {
	

	}
	
	async getSymbol(symbol: string) {
		let { data } = await axios.get(`/raw/crypto/${symbol}.json`);
	
		// console.log(data);
	
		this.crypto_token = data;
	
	} 
	
	created() {
	
	// this.getSymbol(this.symbol);
		// this.ws_stream = new WebSocket('wss://ws.b8s.biz');
		
		// Listen for messages
		this.$socket.addEventListener('message', this.wsOnMessage);
		
	}

  destroyed() {
	
	// this.getSymbol(this.symbol);
		// this.ws_stream = new WebSocket('wss://ws.b8s.biz');
		
		// Listen for messages
		this.$socket.removeEventListener('message', this.wsOnMessage);
		
	}

	
	wsOnMessage(ev: any) {
		let message = JSON.parse(ev.data);
		
		let event_type = message.type;
		
		if(event_type == "price_update") {
			
			let price_update = 	message.data[this.symbol];
			
			this.crypto_token.activity.last = price_update.price;
		
		}
		
	}

	abbreviateNumber(n: number) {
		if (n < 1e3) return n;
		if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
		if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "m";
		if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "Bn";
		if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
	}
	
	ChangeTab(new_tab: string) {
		this.selected_tab = new_tab;
	}
	
	get chart_prices() {
		let frame = parseInt(this.selected_timeframe);

		return this.crypto_token.prices.slice(0, frame);
	}

	@Watch('symbol', {immediate: true})
	onSymbolChange(symbol: any) {
  
		this.getSymbol(symbol)
  
	}

}

