













































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import router from "@/router";
import Chart from "@/components/Chart.vue";
import OrderBook from "@/components/OrderBook.vue";

import axios from 'axios';

// Components

// import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
// import Footer from "@/components/Footer.vue"; // @ is an alias to /src
@Component({
  components: {
    Chart,
    OrderBook
  }
})
export default class ShareView extends Vue {
  
  @Prop() 
  symbol: any;
  
  public share_data: any = null;
  public selected_tab: string = 'details';
  public selected_timeframe: string = '126';
  
  mounted() {
	  
  }
  
  async getSymbol(symbol: string) {
    let { data } = await axios.get(`/raw/au/${symbol}.json`);
    
    // console.log(data);
    
    this.share_data = data;
  } 

  created() {
    
    // this.getSymbol(this.symbol);
  }
  
  abbreviateNumber(n: number) {
    if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "m";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "Bn";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}
  
  ChangeTab(new_tab: string) {
    this.selected_tab = new_tab;
  }
  
  get chart_prices() {
    let frame = parseInt(this.selected_timeframe);
    
    return this.share_data.prices.slice(0, frame);
  }
  
  @Watch('symbol', {immediate: true})
  onSymbolChange(symbol: any) {
      
      this.getSymbol(symbol)
      
  }

}

