


















import { Component, Vue, Prop } from "vue-property-decorator";

import router from "@/router";

import axios from 'axios';

// Components
// import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
// import Footer from "@/components/Footer.vue"; // @ is an alias to /src
@Component({
  components: {
  }
})
export default class Chart extends Vue {
  
  @Prop()
  prices!: any[];
  
  
  mounted() {

  }
  
  get points() {
    
    let points = [];
    
    let prices = this.prices.slice(0,this.prices.length);
    
    prices.reverse();
    
    console.log(this.$refs)
    
    let highest_point = Math.max(... prices.map( item => item.high));
    let lowest_point  = Math.min(... prices.map( item => item.low));
    let range = highest_point - lowest_point;
    
    for(let [index, price] of Object.entries(prices)) {
      
      let x: number = parseInt(index) * (703/prices.length);
      let y = (( highest_point - price.close ) / range) * 300;
      
      points.push( [x, y].join(", ") );
    }
    
    return points.join(" ");
  }

}



